import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  fluentButton,
  fluentCheckbox,
  fluentOption,
  fluentProgressRing,
  fluentSelect,
  provideFluentDesignSystem,
} from '@fluentui/web-components';

import { AppModule } from './app/app.module';
provideFluentDesignSystem().register(
  fluentButton(),
  fluentProgressRing(),
  fluentSelect(),
  fluentOption(),
  fluentCheckbox(),
);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

<div class="size-full pr-6" *ngIf="hasData">
  <div>
    <p>Group by</p>
    <fluent-select
      title="Group by"
      name="selectedOption"
      class="min-w-0 flex-1"
      [(ngModel)]="groupBy"
      (ngModelChange)="groupByChange()"
      ngDefaultControl
    >
      <fluent-option value="day">Day</fluent-option>
      <fluent-option value="month">Month</fluent-option>
    </fluent-select>
  </div>
  <canvas
    baseChart
    [data]="chartData"
    [options]="chartOptions"
    type="line"
  ></canvas>
</div>
<h3 *ngIf="!hasData && !loading">No data found</h3>

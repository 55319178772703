import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { HomeComponent } from './home/home.component';

export const appRoutes: Route[] = [
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
];

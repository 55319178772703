import { Injectable } from '@angular/core';
import { BillingService, LicenseDto } from '@gentext/api-client';
import { LoggingService } from '@gentext/logging';

@Injectable({ providedIn: 'root' })
export class BillingUiService {
  constructor(
    private billingService: BillingService,
    private logging: LoggingService,
  ) {}
  managePlan(license: LicenseDto): Promise<void> {
    return new Promise((resolve, reject) => {
      if (license?.stripeSignUp) {
        this.billingService
          .billingGetPortalUrlPost({
            returnUrl: `${window.location.href}`,
          })
          .subscribe({
            next: (res) => {
              window.location.href = res.url;
              resolve();
            },
            error: (err) => {
              this.logging.exception(err);
              reject();
            },
          });
      } else {
        //if account was puchsased before July 31 2023, send to Marketplace portal
        window.open(
          'https://admin.microsoft.com/Adminportal/Home#/subscriptions',
        );
      }
    });
  }
}

<div class="m-auto w-full px-10 pt-10 text-center 2xl:w-3/4">
  <gentext-alerts-banner [license]="license"></gentext-alerts-banner>
  <fluent-progress-ring
    *ngIf="loading"
    class="mx-auto mt-4"
  ></fluent-progress-ring>
  <p class="text-red-500" *ngIf="hasError; else tmplNoError">
    Something went wrong retrieving your data, please try again later.
  </p>
  <ng-template #tmplNoError>
    <ng-container *ngIf="license">
      <h1 class="text-3xl">Your license</h1>
      <p>Plan: {{ license.planId }}</p>
      <p>
        Words left:
        {{ license.isUnlimited ? '&infin; ' : license.wordsLeft }}
      </p>
      <p *ngIf="license.expiryDateTime">
        End date: {{ license.expiryDateTime | date }}
      </p>
      <div
        class="mt-10 flex justify-center"
        style="flex-direction: column; align-items: center"
      >
        <div *ngIf="license.planId !== 'enterprise'" class="flex-row-buttons">
          <fluent-button (click)="viewGenTextPlans()" appearance="accent"
            >Upgrade</fluent-button
          >
          <fluent-button
            (click)="cancelSubscription()"
            appearance="accent"
            *ngIf="!isFreePlan"
            >Cancel subscription</fluent-button
          >
        </div>

        <ng-container *ngIf="viewPlans">
          <div class="custom-checkbox">
            <input
              id="status"
              type="checkbox"
              name="status"
              [(ngModel)]="isYearlyChecked"
            />
            <label for="status">
              <div
                class="status-switch"
                data-unchecked="Monthly"
                data-checked="Yearly"
              ></div>
            </label>
          </div>
          <div class="flex-row-cards">
            <!-- Premium Plan Card -->
            <mat-card
              class="h-75 group box-border w-80 flex-col justify-between space-y-10 rounded-3xl bg-gray-300 p-5 transition-colors duration-1000 ease-in-out hover:bg-blue-700"
            >
              <div *ngIf="!isYearlyChecked" class="group-hover:text-white">
                <p class="text-base"></p>
                <div class="mb-15 pb-2.5">
                  <p class="text-2xl">Premium</p>
                </div>
                USD 19 per month for usage of all features up to 100,000 words
                per month
              </div>
              <div *ngIf="isYearlyChecked" class="group-hover:text-white">
                <p class="text-base"></p>
                <div class="mb-15 pb-2.5">
                  <p class="text-2xl">Premium</p>
                </div>
                USD 190 per year for usage of all features up to 100,000 words
                per month
              </div>
              <div class="checkmark-section">
                <div class="checkmark-content">
                  <img class="size-6" src="../../assets/green_check.png" />
                  <button class="mr-2.5 rounded-full bg-inherit p-2.5">
                    <p class="text-black">100,000 words/month</p>
                  </button>
                </div>
                <button
                  mat-raised-button
                  color="primary"
                  class="mt-2.5 box-border w-full rounded-full bg-gray-500 p-2.5 text-white group-hover:bg-blue-700"
                  *ngIf="!isYearlyChecked"
                  (click)="upgrade(CheckoutRequestPlan.PremiumMonthly)"
                >
                  Get Premium
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  class="mt-2.5 box-border w-full rounded-full bg-gray-500 p-2.5 text-white group-hover:bg-blue-700"
                  *ngIf="isYearlyChecked"
                  (click)="upgrade(CheckoutRequestPlan.PremiumYearly)"
                >
                  Get Premium
                </button>
              </div>
            </mat-card>

            <!-- Business Plan Card -->
            <mat-card
              class="h-75 group box-border w-80 flex-col justify-between space-y-16 rounded-3xl bg-gray-300 p-5 transition-colors duration-1000 ease-in-out hover:bg-blue-700"
            >
              <div *ngIf="!isYearlyChecked" class="group-hover:text-white">
                <p class="text-base"></p>
                <div class="mb-15 pb-2.5">
                  <p class="text-2xl">Business</p>
                </div>
                USD 49 per month for unlimited usage of all features
              </div>
              <div *ngIf="isYearlyChecked" class="group-hover:text-white">
                <p class="text-base"></p>
                <div class="mb-15 pb-2.5">
                  <p class="text-2xl">Business</p>
                </div>
                USD 490 per year for unlimited usage of all features
              </div>
              <div class="checkmark-section">
                <div class="checkmark-content">
                  <img class="size-6" src="../../assets/green_check.png" />
                  <button class="mr-2.5 rounded-full bg-inherit p-2.5">
                    <p class="text-black">Unlimited words/month</p>
                  </button>
                </div>
                <button
                  mat-raised-button
                  color="primary"
                  class="mt-2.5 box-border w-full rounded-full bg-gray-500 p-2.5 text-white group-hover:bg-blue-700"
                  *ngIf="!isYearlyChecked"
                  (click)="upgrade(CheckoutRequestPlan.BusinessMonthly)"
                >
                  Get Business
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  class="mt-2.5 box-border w-full rounded-full bg-gray-500 p-2.5 text-white group-hover:bg-blue-700"
                  *ngIf="isYearlyChecked"
                  (click)="upgrade(CheckoutRequestPlan.BusinessYearly)"
                >
                  Get Business
                </button>
              </div>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <hr class="my-6" />
    <h1 class="text-xl">Usage overview</h1>
    <gentext-usage-graph
      class="graph mb-20 mt-6 block w-full"
      [apiBaseUrl]="apiBaseUrl"
      [usageRecordGet]="usageRecordGet"
    ></gentext-usage-graph>
    <ng-container *ngIf="userIsAdmin">
      <hr class="mb-6 mt-10" />
      <h1 class="text-xl">ADMIN - All users usage overview</h1>
      <gentext-admin-users
        class="mb-[100px] mt-6 block w-full"
      ></gentext-admin-users>
    </ng-container>
  </ng-template>
</div>

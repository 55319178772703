<fluent-button
  appearance="accent"
  class="mb-4 hover:bg-blue-700"
  (click)="downloadCsv()"
>
  Download CSV
</fluent-button>

<div class="flex items-start justify-center gap-4">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput [(ngModel)]="filter" />
  </mat-form-field>
  <button
    mat-raised-button
    color="accent"
    class="bg-gray-500 p-2.5 text-white hover:bg-blue-700"
    (click)="applyFilter()"
  >
    Filter
  </button>
</div>

<table mat-table [dataSource]="dataSource" class="w-full" matSort>
  <ng-container matColumnDef="emailAddress">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="emailAddress">
      Email
    </th>
    <td mat-cell *matCellDef="let element">{{ element.emailAddress }}</td>
  </ng-container>
  <ng-container matColumnDef="userDisplayName">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.userDisplayName }}</td>
  </ng-container>
  <ng-container matColumnDef="words">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="wordsLeft">
      Words left
    </th>
    <td mat-cell *matCellDef="let element">{{ element.wordsLeft }}</td>
  </ng-container>
  <ng-container matColumnDef="dateTime">
    <th mat-header-cell *matHeaderCellDef>End date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.expiryDateTime | date: 'MMM yyyy' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="planId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="planId">Plan</th>
    <td mat-cell *matCellDef="let element">
      {{ element.planId }}
    </td>
  </ng-container>
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef>Delete</th>
    <td mat-cell *matCellDef="let element">
      <fluent-button (click)="deleteLicense(element)" appearance="accent"
        >Delete</fluent-button
      >
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<mat-paginator
  [pageSizeOptions]="[10, 50, 100, 200, 500]"
  showFirstLastButtons
  [length]="totalCount"
  aria-label="Select number of rows"
>
</mat-paginator>

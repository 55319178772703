<div>
  <p class="pb-3 text-red-500" *ngIf="hasError">
    Something went wrong.<br />Please contact us at
    <a href="mailto:support@gentext.ai">support&#64;gentext.ai</a>.
  </p>
  <div
    *ngIf="showFailedPaymentBanner"
    (click)="clickFailedPaymentBanner()"
    class="mb-4 flex w-full cursor-pointer flex-row items-center justify-center bg-red-700 p-4 text-center text-white"
  >
    <fa-icon [icon]="['fas', 'triangle-exclamation']"></fa-icon>
    <span class="pl-2">
      A recent payment has failed. Update your payment method to continue your
      plan.
    </span>
  </div>
</div>

<div class="m-auto w-1/2 pt-10 text-center">
  <p class="text-red-500" *ngIf="hasUnexpectedError">
    Something went wrong. Please contact us at
    <a href="mailto:support@gentext.ai">support&#64;gentext.ai</a>.
  </p>
  <p class="text-red-500" *ngIf="hasBadRequestError">
    The token has expired or is invalid. Please contact us at
    <a href="mailto:support@gentext.ai">support&#64;gentext.ai</a>.
  </p>
  <fluent-progress-ring
    *ngIf="loading"
    class="mx-auto mt-4"
  ></fluent-progress-ring>
  <ng-container *ngIf="resolveResponse as r">
    <h1 class="text-3xl">Thank you for your subscription</h1>
    <p class="my-6">
      Please verify the following information is correct. If not, please contact
      us at
      <a href="mailto:support@gentext.ai">support&#64;gentext.ai</a>.
    </p>

    <p>
      Plan: {{ r.planType?.planId === 'business' ? 'Business' : 'Premium' }}
    </p>
    <p>
      Term:
      {{ r.planType?.term === PlanTerm.Monthly ? 'Monthly' : 'Annual' }}
    </p>

    <p *ngIf="r.autoRenews; else tmplNoAutoRenew">Auto renews</p>
    <ng-template #tmplNoAutoRenew>
      <p>Does not auto renew</p>
    </ng-template>
    <p>End date: {{ r.endDate | date }}</p>
    <p>Email: {{ r.userEmail }}</p>

    <fluent-button appearance="accent" class="mt-6" (click)="home()"
      >Home</fluent-button
    >
  </ng-container>
</div>

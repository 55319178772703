import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BillingService,
  PlanTerm,
  ResolvePurchaseResponseDto,
} from '@gentext/api-client';
import { LoggingService } from '@gentext/logging';

@Component({
  selector: 'gentext-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css'],
})
export class ConfirmationComponent implements OnInit {
  hasUnexpectedError = false;
  hasBadRequestError = false;
  loading = false;
  activated = false;

  PlanTerm = PlanTerm;
  resolveResponse: ResolvePurchaseResponseDto | undefined;
  get stripeToken() {
    return this.route.snapshot.queryParamMap.get('session_id');
  }
  constructor(
    private route: ActivatedRoute,
    private logging: LoggingService,
    private billingService: BillingService,
  ) {}
  ngOnInit() {
    this.resolve();
  }
  resolve() {
    if (!this.stripeToken) {
      this.logging.trace({
        message: 'No stripe token found',
      });
      this.hasUnexpectedError = true;
      return;
    }
    this.loading = true;
    this.billingService.billingResolveGet(this.stripeToken).subscribe({
      error: (err) => {
        this.logging.exception(err);
        const httpError = err as HttpErrorResponse;
        if (httpError?.status === 400) {
          this.hasBadRequestError = true;
        } else {
          this.hasUnexpectedError = true;
        }
        this.loading = false;
      },
      next: (res) => {
        this.logging.trace({
          message: `response received from billing resolve`,
          properties: {
            res,
          },
        });
        this.resolveResponse = res;
        this.loading = false;
      },
    });
  }

  home() {
    window.location.href = `http://${process.env['NX_ACCOUNT_GENTEXT_HOST']}`;
  }
}
